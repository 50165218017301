<template>
  <div>
    <v-dialog v-model="dialog" persistent scrollable width="600">
      <v-card>
        <v-card-title>
          Information de POS
          <v-spacer/>
          <v-icon @click="close">mdi-close</v-icon>
        </v-card-title>
        <v-divider/>
        <v-card-text>
          <v-simple-table class="table-border mt-2">
            <template v-slot:default>
              <tbody>
              <tr>
                <td><span class="text--primary text-uppercase">Photo : </span></td>
                <td>
                  <v-avatar size="38">
                    <v-img v-if="wholesaler.wholesaler_account && wholesaler.wholesaler_account.photo"
                           :src="FILE_URL + wholesaler.wholesaler_account.photo"></v-img>
                    <v-img v-else :src="require('@/assets/avatar2.png')"></v-img>
                  </v-avatar>
                </td>
              </tr>
              <tr>
                <td><span id="key_26" class="text--primary text-uppercase">{{ keyword.key_26 }} : </span></td>
                <td>{{ wholesaler.code }}</td>
              </tr>
              <tr>
                <td><span id="key_1" class="text--primary text-uppercase">{{ keyword.key_1 }} : </span></td>
                <td>{{ wholesaler.type }}</td>
              </tr>
              <tr>
                <td><span id="key_27" class="text--primary text-uppercase">{{ keyword.key_27 }} :</span></td>
                <td>{{ wholesaler.name }}</td>
              </tr>
              <tr>
                <td><span id="key_3" class="text--primary text-uppercase">{{ keyword.key_3 }} :</span></td>
                <td>{{ wholesaler.city }}</td>
              </tr>

              <tr>
                <td><span class="text--primary text-uppercase">Région :</span></td>
                <td>{{ wholesaler.region }}</td>
              </tr>


              <tr>
                <td><span id="key_40" class="text--primary text-uppercase">{{ keyword.key_40 }}:</span></td>
                <td>{{ wholesaler.address }}</td>
              </tr>
              <tr>
                <td><span id="key_28" class="text--primary text-uppercase">{{ keyword.key_28 }} :</span></td>
                <td>{{ wholesaler.phone }}</td>
              </tr>

              <tr>
                <td><span id="key_31" class="text--primary text-uppercase">{{ keyword.key_31 }} :</span></td>
                <td>{{ wholesaler.landmark }}</td>
              </tr>

              <tr>
                <td><span class="text--primary text-uppercase">Pts des Codes QR : </span></td>
                <td>
                  <v-chip color="blue" dark small>
                    {{ wholesaler.qr_code_points | toCurrency }}
                  </v-chip>

                </td>
              </tr>

              <tr>
                <td><span class="text--primary text-uppercase">Pts des KPI's  : </span></td>
                <td>
                  <v-chip color="blue" dark small>
                    {{ wholesaler.kpi_points | toCurrency }}
                  </v-chip>

                </td>
              </tr>

              <tr>
                <td><span class="text--primary text-uppercase">Compte Gifty : </span></td>
                <td>
                  <v-icon v-if="wholesaler.wholesaler_account && wholesaler.wholesaler_account.gifty_id"
                          color="success">
                    mdi-check-circle
                  </v-icon>
                  <v-icon v-else color="error">mdi-close-circle</v-icon>
                </td>
              </tr>
              <tr>
                <td><span class="text--primary text-uppercase">Compte TIP : </span></td>
                <td>
                  <v-icon v-if="!wholesaler.wholesaler_account" color="error">mdi-close-circle</v-icon>
                  <v-icon v-else color="success">mdi-check-circle</v-icon>
                </td>
              </tr>

              </tbody>
            </template>
          </v-simple-table>
          <google-maps
              v-if="wholesaler.latitude && wholesaler.longitude"
              :position="{lat:wholesaler.latitude,lng:wholesaler.longitude}"
              class="mt-2"
              height="250px"/>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>

import GoogleMaps from "../../components/Maps/googleMaps";

export default {
  components: {GoogleMaps},
  props: ['dialog', 'wholesaler'],
  data() {
    return {
      FILE_URL: process.env.VUE_APP_FILE_URL
    }
  },
  methods: {
    close() {
      this.$emit('update:dialog', false)
    }
  },
  computed: {
    keyword() {
      return this.$store.state.keyword
    },
  }
}
</script>

<style scoped>

</style>