<template>
  <div>
    <div class="pa-4">
      <label class="text--secondary">Compte Gifty</label>
      <v-radio-group v-model="filter.have_gifty_account"
                     class="mt-2"
                     row>
        <v-radio label="Oui"
                 off-icon="mdi-checkbox-blank-circle-outline"
                 on-icon="mdi-checkbox-marked-circle"
                 value="yes"></v-radio>
        <v-radio label="Non"
                 off-icon="mdi-checkbox-blank-circle-outline"
                 on-icon="mdi-checkbox-marked-circle"
                 value="no"></v-radio>
        <v-radio label="Tous"
                 off-icon="mdi-checkbox-blank-circle-outline"
                 on-icon="mdi-checkbox-marked-circle"
                 value="all"></v-radio>
      </v-radio-group>

      <label class="text--secondary">Compte TIP</label>
      <v-radio-group v-model="filter.have_tip_account"
                     class="mt-2"
                     row>
        <v-radio label="Oui"
                 off-icon="mdi-checkbox-blank-circle-outline"
                 on-icon="mdi-checkbox-marked-circle"
                 value="yes"></v-radio>
        <v-radio label="Non"
                 off-icon="mdi-checkbox-blank-circle-outline"
                 on-icon="mdi-checkbox-marked-circle"
                 value="no"></v-radio>
        <v-radio label="Tous"
                 off-icon="mdi-checkbox-blank-circle-outline"
                 on-icon="mdi-checkbox-marked-circle"
                 value="all"></v-radio>
      </v-radio-group>


      <v-select
          id="key_1"
          v-model="filter.types"
          :items="filter_elements.types"
          :label="keyword.key_1"
          chips
          class="rounded mb-2"
          deletable-chips
          dense
          filled
          hide-details
          item-text="name"
          item-value="name"
          multiple
          rounded
          small-chips/>

      <v-autocomplete
          id="key_3"
          v-model="filter.cities"
          :items="cities"
          :label="keyword.key_3"
          chips
          class="rounded mb-2"
          deletable-chips
          dense
          filled
          hide-details
          item-text="name"
          item-value="name"
          multiple
          rounded
          small-chips>
        <template v-slot:prepend-item>
          <v-list-item ripple @click="toggle" @mousedown.prevent>
            <v-list-item-action>
              <v-icon :color="filter.cities.length > 0 ? 'primary' : ''">
                {{ icon }}
              </v-icon>
            </v-list-item-action>
            <v-list-item-content>
              <v-list-item-title>
                Sélectionner tout
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-divider class="mt-2"></v-divider>
        </template>
      </v-autocomplete>

      <v-autocomplete v-model="filter.region"
                      :items="['Centre','Est','Ouest']"
                      label="Région"
                      clearable
                      class="rounded mb-2"
                      dense
                      filled
                      hide-details
                      rounded
      >
      </v-autocomplete>

      <v-btn block class="rounded-lg" color="primary" depressed large @click="onFilter">
        Filtrer
      </v-btn>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      filter: {
        have_gifty_account: 'all',
        have_tip_account: 'all',
        cities: [],
        region: '',
      }
    }
  },
  methods: {
    onFilter() {
      this.$emit('filter', this.filter)
    },
    refresh() {
      this.filter = {
        have_gifty_account: 'all',
        have_tip_account: 'all',
        region: '',
        cities: [],
      }
    },
    toggle() {
      if (this.filter.cities.length === this.cities.length) {
        this.filter.cities = []
      } else {
        this.filter.cities = this.cities.map(el => el.name)
      }
    },
  },
  computed: {
    filter_elements() {
      return this.$store.state.salepoint_filter_elements
    },
    cities() {
      return this.$store.state.cities
    },
    keyword() {
      return this.$store.state.keyword
    },
    icon() {
      if (this.filter.cities.length === this.cities.length) return 'mdi-close-box'
      if (this.filter.cities.length > 0 && this.filter.cities.length !== this.cities.length) return 'mdi-minus-box'
      return 'mdi-checkbox-blank-outline'
    },
  }
}
</script>

<style scoped>

</style>